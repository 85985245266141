import {Flex, Popover, Tooltip} from "antd";
import {MoreOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import React, { useState } from "react";
const RenderColumnTitle = ({column_new, formula, name, dataIndex, column, showModalEdit, showModalDelete, editable, empty}) => {
    const [popoverVisible, setPopoverVisible] = useState(false);

    const handleMoreBtnClick = (e) => {
        setPopoverVisible(false);
        setPopoverVisible((prev)=>{
            return !prev;
        });
        e.stopPropagation();
    };
    const handleHover = (e) => {
        e.stopPropagation();
    };
    const handlePopoverClose = () => {
        setPopoverVisible(false);
      };
    const handleColumnEdit = (e,title) => {
        setPopoverVisible(false);
        showModalEdit(title,column);
      e.stopPropagation();
    };
    const handleColumnDelete = (e,title) => {
        showModalDelete(title,column);
      e.stopPropagation();
    };


    const submenu_content = (
      <ul className="sidebar_parentmenu-list">
        <li onClick={(e)=>{handleColumnEdit(e,name)}}>
          <EditOutlined />
          <span>Переименовать</span>
        </li>
        <li onClick={(e)=>{handleColumnDelete(e,name)}}>
          <DeleteOutlined />
          <span>Удалить</span>
        </li>
      </ul>
    );
    if(!editable){
        return (
          <Flex justify="space-between" align="center">
            <span style={{lineHeight: 3}}title={name}>{name.length > 22?`${name.slice(0,22)}...`:name}<span style={{ color: "#ccc",fontSize: 10,position:'absolute',left:'0', top: -12 }}>Пустых: {empty} {column_new !== "" ? `Значение из: ${column_new}` : ''}</span></span>

          </Flex>
        );
    }
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Popover
          content={submenu_content}
          placement="right"
          trigger="click"
          onOpenChange={handlePopoverClose}
          open={popoverVisible}
        >
          <Flex justify="space-between" align="center">
            <span style={{lineHeight: 3}}title={name}>{name.length > 22?`${name.slice(0,22)}...`:name}<span style={{ color: "#ccc",fontSize: 10,position:'absolute',left:'0', top: -12 }}>Пустых: {empty}</span>
                <span style={{ color: "#ccc",fontSize: 10,position:'absolute',right:'0', top: -12 }}>
                    <Tooltip title={`Формула: ${formula}`} color="#4A5EFF" overlayStyle={{fontSize: 11, maxWidth: '280px', lineHeight: '18px'}}>
                        <InfoCircleOutlined />
                  </Tooltip>
                </span>
            </span>
            <button
              onClick={handleMoreBtnClick}
              onMouseOver={handleHover}
              className="submenu_morebtn"
            >
              <MoreOutlined style={{ fontSize: "16px", color: "#111" }} />
            </button>
          </Flex>
        </Popover>

      </div>
    );
  };
  export default RenderColumnTitle;
