/*FeedTable.jsx*/
import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Table,
  Divider,
  Popover,
  Button,
  Flex,
  message,
  Modal,
  Select,
} from "antd";
import { observer } from "mobx-react-lite";
import { PlusOutlined } from "@ant-design/icons";
import { Context } from "../..";
import { EditableCell, EditableRow } from "../TableComponents/EditableRow";
import FeedModals from "./FeedModals";
import FilterTable from "./FilterTable";
import { prepareRows, prepareColumns } from "../../dto";
import useFeedModals from "../../hooks/useFeedModals";
import AddNewFeed from "./Modals/AddNewFeed";
import ModalAddColumn from "./Modals/ModalAddColumn";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../ui/Modals/ErrorModal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableHeaderCell from "../TableComponents/DraggableHeaderCell";
import FeedDownloadXLSX from "./FeedDownloadXLSX";

const FeedTable2 = ({ tableid, projectId, feed, setDashboard }) => {
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const {
    editModalVisible,
    setEditModalVisible,
    deleteModalVisible,
    setDeleteModalVisible,
    showModalEdit,
    showModalDelete,
    setNewTitle,
    currColumn,
    fetchFeed,
    newTitle,
  } = useFeedModals();
  const [messageApi, contextHolder] = message.useMessage();
  // const [changed, setChanged] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterslen, setFilterslen] = useState(0);

  // useEffect(() => {
  //   setChanged(feed.changed);
  // }, [feed]);

  const prepareFilters = (data) =>
    data.map((el) => ({
      id: el.id,
      filtermethod: el.arguments.operator,
      type: el.arguments.type || "number",
      filtervalue: el.arguments.value,
      filtercolumn: el.arguments.column,
      disabled: true,
      new: false,
    }));

  const fetchFeedFilters = useCallback(async () => {
    try {
      const storefilter = await store.getFilters(tableid);
      setFilterslen(storefilter.data.length);
      setFilters(prepareFilters(storefilter.data));
    } catch (error) {
      console.error("Error fetching feed filters:", error);
    }
  }, [store, tableid]);

  const handleFilter = async (filters) => {
    let pfilters = filters.map((filter) => ({
      name: "Filter",
      connector: "AND",
      operator: filter.filtermethod,
      column: filter.filtercolumn,
      value: filter.filtervalue,
      new: filter.new === false ? false : true,
    }));

    pfilters = pfilters.filter((row) => row.new);

    const filterPromises = pfilters.map((filter) =>
      store.addFilter(tableid, filter)
    );
    try {
      await Promise.all(filterPromises);
      await fetchFeedFilters();
      await fetchData();
      // await updateDashboard();
      // const feed = await store.getFeed(tableid);
      await store.getFeed(tableid);
      // setChanged(feed.data.changed);
    } catch (error) {
      console.error("Error setting filters:", error);
    }
  };

  const removeFilter = async (filterid) => {
    try {
      await store.removeFilter(tableid, filterid);
      await fetchFeedFilters();
      await fetchData();
      // await updateDashboard();
      await store.getFeed(tableid);
      // const feed = await store.getFeed(tableid);
      // setChanged(feed.data.changed);
    } catch (error) {
      console.error("Error removing filter:", error);
    }
  };

  const removeAllFilters = async () => {
    try {
      await Promise.all(
        filters.map((filter) => store.removeFilter(tableid, filter.id))
      );
      await fetchFeedFilters();
      // await updateData();
      await fetchData();
      // await updateDashboard();
      await store.getFeed(tableid);
      // const feed = await store.getFeed(tableid);
      // setChanged(feed.data.changed);
    } catch (error) {
      console.error("Error removing all filters:", error);
    }
  };

  const [feedColumns, setFeedColumns] = useState([]);
  const [feedColumnsSelect, setFeedColumnsSelect] = useState([]);
  const [feedParents, setFeedParents] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [oldColumns, setOldColumns] = useState([]);

  const saveColumnOrder = (columns) => {
    const order = columns.map((col) => col.key);
    // setColumnOrder(order);
    localStorage.setItem(`columnOrder_${tableid}`, JSON.stringify(order));
  };

  const restoreColumnOrder = (columns) => {
    const order = JSON.parse(localStorage.getItem(`columnOrder_${tableid}`));
    if (!order) return columns;

    // Найти колонки в order, которые есть в columns
    const orderedColumns = order
      .map((key) => columns.find((col) => col.key === key))
      .filter((col) => col !== undefined); // Удалить undefined значения

    // Оставшиеся колонки, которые не были найдены в order
    const remainingColumns = columns.filter((col) => !order.includes(col.key));

    return [...orderedColumns, ...remainingColumns];
  };

  const fetchFeedColumns = useCallback(async () => {
    setLoading(true);
    try {
      const response = await store.getFeedColumns(tableid);
      const preparedColumnsSelect = response.data.map((el) => ({
        label: el.name,
        value: el.id,
        columntype: el.type.name,
        metadata: el.metadata,
        hide: el.attr_name.startsWith("param"),
      }));
      // console.log(preparedColumnsSelect)
      setFeedColumnsSelect(preparedColumnsSelect);

      console.log(feed?.type)

      restoreHiddenColumns();
      const preparedColumns = prepareColumns(
        response.data,
        showModalEdit,
        showModalDelete,
        hiddenColumns.columns,
        feed?.type === 1
      );
      const orderedColumns = restoreColumnOrder(preparedColumns);
      setFeedColumns(orderedColumns);

      const filteredNewColumns = response.data.filter(item => item.for_cache === true);
      const preparedNewColumns = filteredNewColumns.map((el) => ({
        value: el.id,
        label: el.name,
      }));
      setNewColumns(preparedNewColumns);

      const filteredOldColumns = response.data.filter(item => item.for_cache !== true && item.name !== "offer_id" && item.name !== "categoryId");
      const preparedOldColumns = filteredOldColumns.map((el) => ({
        value: el.id,
        label: el.name,
      }));
      setOldColumns(preparedOldColumns);

      return response.data;
    } catch (error) {
      console.error("Error fetching feed columns:", error);
    } finally {
      setLoading(false);
    }
  }, [store, tableid, showModalEdit, showModalDelete]);

  useEffect(() => {
    fetchFeedFilters();
  }, [tableid, fetchFeedFilters]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorting: [],
  });

  const updateData = async () => {
    setLoading(true);
    try {
      const cols = await fetchFeedColumns();
      const feedRows = await store.getFeedRows(tableid, tableParams);
      setTotal(feedRows.data.total);
      setData(prepareRows(feedRows.data.items, cols));
    } catch (error) {
      console.error("Error fetching feed rows:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const cols = await fetchFeedColumns();
      const feedRows = await store.getFeedRows(tableid, tableParams);
      setTotal(feedRows.data.total);
      setData(prepareRows(feedRows.data.items, cols));
      setTableParams((prevParams) => ({
        ...prevParams,
        pagination: {
          ...prevParams.pagination,
          current: feedRows.data.page,
          pageSize: feedRows.data.size,
          total: feedRows.data.total,
        },
      }));

      const response = await store.getParentFeed(tableid);
      const preparedFeedParents = response.data.feed_parents.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setFeedParents(preparedFeedParents);
    } catch (error) {
      console.error("Error fetching feed rows:", error);
    } finally {
      setLoading(false);
    }
  }, [fetchFeedColumns, store, tableParams, tableid]);

  useEffect(() => {
    setHiddenColumns({ showModal: false, columns: [] });
    fetchData();
  }, [tableid, JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    const sorting = sorter.order
      ? [
          {
            column: sorter.field,
            order: sorter.order === "ascend" ? "ASC" : "DESC",
          },
        ]
      : [];

    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
      sorting,
    }));

    if (pagination.pageSize !== tableParams.pagination.pageSize) {
      setData([]);
    }
  };
  // const updateDashboard = async () => {
  //   try {
  //     const dashboard = await store.getDashboard(projectId);
  //     console.log(dashboard.data);
  //     setDashboard(dashboard.data);
  //   } catch (error) {
  //     console.error("Error updating dashboard:", error);
  //   }
  // };

  const handleSave = async (value, row, cell) => {
    try {
      await store.editCell(tableid, value, row, cell);
      fetchData();
    } catch (error) {
      console.error("Error saving cell:", error);
    }
  };

  const deleteColumn = async (columnid) => {
    try {
      await store.deleteColumn(tableid, columnid);
      removeColumnFromOrder(tableid, columnid);
      await updateData();
    } catch (error) {
      console.error("Error deleting column:", error);
    }
  };

  const removeColumnFromOrder = (tableid, columnId) => {
    let order = JSON.parse(localStorage.getItem(`columnOrder_${tableid}`));
    if (!order) return;
    order = order.filter((key) => key !== `render+${columnId}`);
    localStorage.setItem(`columnOrder_${tableid}`, JSON.stringify(order));
  };

  const moveColumn = (dragIndex, hoverIndex) => {
    const dragColumn = feedColumns[dragIndex];
    const newColumns = [...feedColumns];
    newColumns.splice(dragIndex, 1);
    newColumns.splice(hoverIndex, 0, dragColumn);
    setFeedColumns(newColumns);
    saveColumnOrder(newColumns);
  };

  const columnsEditable = feedColumns.map((col, index) => {
    // console.log(col)
    return {
      ...col,
      key: `column_${index}`,
      width: col.columntype === "url" ? 480 : 250,
      sorter: true,
      hidden: col.attr_name === "param" || col.attr_name === "param~name",
      textWrap: "word-break",
      ellipsis: col.columntype !== "url",
      className:
        col.attr_name === "zoomos" || col.attr_name === "calculated" || col.attr_name === "google" || col.attr_name === "condition"
            || col.attr_name === "current_date"
          ? "bluecolumn"
          : "",
      onCell: (record) => ({
        record,
        editable:
          col.columntype !== "picture" &&
          col.dataIndex !== "md_param" &&
          col.attr_name !== "zoomos",
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
      onHeaderCell: (column) => ({
        index,
        moveColumn,
      }),
    };
  });

  {(!feed.parent)  && (
    columnsEditable.push({
      title: (
        <Popover
          content={
            <ModalAddColumn
              getIntegration={store.getIntegration}
              getIntegrations={store.getIntegrations}
              addColumnWithType={store.addColumnWithType}
              tablerows={data}
              tableid={tableid}
              fetchFeed={fetchFeed}
              setLoading={setLoading}
              projectId={projectId}
              feedColumns={feedColumns}
              selectColumns={feedColumnsSelect}
              addNewColumn={store.addNewColumn}
              updateData={updateData}
              fetchFeedColumns={fetchFeedColumns}
            />
          }
          trigger="click"
          placement="left"
        >
          <button className="SidebarProjectsAddTable" type="primary">
            <PlusOutlined style={{ color: "#999999" }} />{" "}
          </button>
        </Popover>
      ),
      dataIndex: "new",
      align: "center",
      fixed: "right",
      width: 40,
      className: "newcolumn",
      key: "new",
      render: () => <></>,
    })
  )}

  const AddNewFeedProcess = (content, type, duration = 0) => {
    messageApi.open({
      type: type,
      content: content,
      duration: duration,
    });
    if (duration) {
      setTimeout(messageApi.destroy, duration);
    }
  };

  const RewriteParentFeedProcess = (content, type, duration = 0) => {
    messageApi.open({
      type: type,
      content: content,
      duration: duration,
    });
    if (duration) {
      setTimeout(messageApi.destroy, duration);
    }
  };

  const addNewFeed = async (id, name, newColumns, oldColumns) => {
    AddNewFeedProcess("Создаем фид...", "loading");
    try {
      const response = await store.addNewFeed(id, name, newColumns, oldColumns);
      if (response?.data?.id) {
        setNewFeedID(response.data.id);
        setShowSuccessAddNewFeed(true);
        messageApi.destroy();
      } else {
        console.log(response);
        messageApi.destroy();
        if (response?.status === 422) {
          message.warning(response?.data?.detail);
        } else {
          message.warning("Создание фида затянулось, обновите страницу позже");
        }
      }
    } catch (error) {
      console.error("Error adding new feed:", error);
    }
  };

  const rewriteParentFeed = async (id, newColumns, oldColumns) => {
    RewriteParentFeedProcess("Перезаписываем фид...", "loading");
    try {
      const response = await store.rewriteParentFeed(id, newColumns, oldColumns);
      if (response?.data?.detail) {
        setRewriteFeedID(id);
        setShowSuccessRewriteFeed(true);
        messageApi.destroy();
      } else {
        console.log(response);
        messageApi.destroy();
        if (response?.status === 422) {
          message.warning(response?.data?.detail);
        } else {
          message.warning("Перезапись фида затянулось, обновите страницу позже");
        }
      }
    } catch (error) {
      console.error("Error rewrites feed:", error);
    }
  };

  const btnCallbackAddNewFeed = () => {
    navigate(`/feeds/${newFeedID}`);
    setShowSuccessAddNewFeed(false);
  };

  const btnCallbackRewriteFeed = () => {
    navigate(`/feeds/${rewriteFeedID}`);
    setShowSuccessRewriteFeed(false);
  };

  const [showSuccessAddNewFeed, setShowSuccessAddNewFeed] = useState(false);
  const [newFeedID, setNewFeedID] = useState(null);

  const [showSuccessRewriteFeed, setShowSuccessRewriteFeed] = useState(false);
  const [rewriteFeedID, setRewriteFeedID] = useState(null);

  const [hiddenColumns, setHiddenColumns] = useState({
    showModal: false,
    columns: [],
  });

  const handleCancelHideColumns = () => {
    setHiddenColumns((prev) => ({ showModal: false, columns: prev.columns }));
    updateData();
  };
  const handleClearHideColumns = () => {
    localStorage.setItem(`columnHidden_${tableid}`, JSON.stringify([]));
    setHiddenColumns((prev) => ({ showModal: prev.showModal, columns: [] }));
    updateData();
  };

  const handleShowHideColumns = () => {
    setHiddenColumns((prev) => ({ showModal: true, columns: prev.columns }));
  };
  const handleChangeHiddenColumns = (columns) => {
    // localStorage.setItem(`columnHidden_${tableid}`, JSON.stringify(columns));
    setHiddenColumns((prev) => ({
      showModal: prev.showModal,
      columns: columns,
    }));
    // updateData();
  };
  const handleHideColumns = () => {
    localStorage.setItem(`columnHidden_${tableid}`, JSON.stringify(hiddenColumns.columns));
    updateData();
  };
  const restoreHiddenColumns = () => {
    const hiddenColumns = JSON.parse(
      localStorage.getItem(`columnHidden_${tableid}`)
    );
    if (!hiddenColumns) return;
    setHiddenColumns((prev) => ({
      showModal: prev.showModal,
      columns: hiddenColumns,
    }));
  };
  useEffect(() => {
    setHiddenColumns({ showModal: false, columns: [] });
  }, [tableid]);
  return (
    <DndProvider backend={HTML5Backend}>
      {contextHolder}

      {/*<Divider style={{ marginTop: 36, marginBottom: 28 }}></Divider>*/}
      {/*<div className="d"></div>*/}
      <h2 style={{ marginBottom: 6 }}>Таблица с данными</h2>
      <div style={{ marginTop: 10, marginBottom: 26 }}>Строк: {total}</div>
      <Flex justify="space-between" align="center">
        <Flex gap={10}>
          <Button
            size="large"
            onClick={handleShowHideColumns}
            type="primary"
            className="btn"

          >
            Скрыто {hiddenColumns.columns.length} из{" "}
            {feedColumnsSelect.filter((el) => el.hide === false).length}
          </Button>
          <div className="">
            <FilterTable
              filters={filters}
              setFilters={setFilters}
              tableid={tableid}
              removeAllFilters={removeAllFilters}
              columns={feedColumnsSelect}
              filterslen={filterslen}
              handleFilter={handleFilter}
              removeFilter={removeFilter}
            />
          </div>
        </Flex>
        <Flex gap={10}>
          {(!feed.parent)  && (
              <AddNewFeed addNewFeed={addNewFeed} rewriteParentFeed={rewriteParentFeed} feedParents={feedParents} newColumns={newColumns} oldColumns={oldColumns} tableid={tableid} />
          )}
          <FeedDownloadXLSX
            tableid={tableid}
            download={store.downloadFeedXlsx}
          />
        </Flex>
      </Flex>

      <Table
        components={{
          header: {
            cell: DraggableHeaderCell,
          },
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        columns={columnsEditable}
        rowClassName={() => "editable-row"}
        bordered
        key={"table"}
        rowKey={(record) => record.id}
        dataSource={data}
        style={{ marginTop: 30 }}
        pagination={tableParams.pagination}
        scroll={{ x: columnsEditable.length * 270, y: "70vh" }}
        loading={loading}
        onChange={handleTableChange}
      />
      <FeedModals
        deleteColumn={deleteColumn}
        renameColumn={store.renameColumn}
        tableid={tableid}
        setNewTitle={setNewTitle}
        setEditModalVisible={setEditModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        editModalVisible={editModalVisible}
        currColumn={currColumn}
        newTitle={newTitle}
        deleteModalVisible={deleteModalVisible}
        fetchData={fetchData}
        fetchFeedColumns={fetchFeedColumns}
        updateData={updateData}
      />
      <ErrorModal
        isModalErrorOpen={showSuccessAddNewFeed}
        setIsModalErrorOpen={setShowSuccessAddNewFeed}
        btnText="Перейти"
        type="success"
        btnCallback={btnCallbackAddNewFeed}
        text="Фид успешно создан"
      />
      <ErrorModal
        isModalErrorOpen={showSuccessRewriteFeed}
        setIsModalErrorOpen={setShowSuccessRewriteFeed}
        btnText="Перейти"
        type="success"
        btnCallback={btnCallbackRewriteFeed}
        text="Фид успешно перезаписан"
      />
      <Modal
        title="Скрыть колонки"
        open={hiddenColumns.showModal}
        footer={null}
        onCancel={handleCancelHideColumns}
      >
        <div className="errormodal_content ">
          <div className="content">
            <Select
              mode="tags"
              placeholder="Выберите колонки для скрытия"
              // defaultValue={['a10', 'c12']}
              onChange={handleChangeHiddenColumns}
              style={{
                width: "100%",
              }}
              value={feedColumnsSelect.filter((el) => {
                return hiddenColumns.columns.includes(el.value);
              })}
              options={feedColumnsSelect.filter((el) => el.hide === false)}
            />
            <Flex>

            {hiddenColumns.columns.length > 0 && (
              <Button
              className="btn"
              style={{ marginTop: 20 }}
              onClick={handleClearHideColumns}
              type="primary"
              >
                Показать все
              </Button>
            )}
             <Button
              className="btn"
              style={{ marginTop: 20 }}
              onClick={handleHideColumns}
              type="primary"
              >
                Скрыть столбцы
              </Button>
            </Flex>
          </div>
        </div>
      </Modal>
    </DndProvider>
  );
};

export default observer(FeedTable2);
